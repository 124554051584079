import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-3 col-md-4 col-sm-12 footer-info footer-col-1">
                <div className="footer-address">
                  <h4>REACH US</h4>
                  <a href="tel:18001237191" style={{ color: "#444444" }}>
                    <p style={{ marginBottom: "0.5rem" }}>
                      <strong>Toll Free :</strong> 1800 123 7191
                    </p>
                  </a>
                  <a href="https://wa.me/917676326137" target={"_blank"}>
                    <div className="d-flex align-items-center mb-2 whatsapp_container">
                      <i className="fab fa-whatsapp"></i>
                      <span>+91 7676326137</span>
                    </div>
                  </a>
                  <a
                    href="https://goo.gl/maps/3Dz5vjWsxVNRBm5u5"
                    style={{ color: "#444444" }}
                    target={"_blank"}
                  >
                    <span>
                      <span>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                      CS International School
                    </span>
                    <br />
                    <span>18 Bilwaradahali, Near Area 83</span>
                    <br />
                    <span>Bannerghatta Road, Bangalore &minus; 560 083</span>
                    <br />
                    <span>Karnataka, India.</span>
                  </a>
                </div>
                <div className="social-links mt-3">
                  <ul className="d-flex">
                    <a
                      href="https://www.facebook.com/csinternationalschool"
                      target="_blank"
                    >
                      <Tooltip title="Facebook">
                        <li>
                          <i className="fab fa-facebook"></i>
                        </li>
                      </Tooltip>
                    </a>
                    <a
                      href="https://www.instagram.com/csinternationalschool/"
                      target="_blank"
                    >
                      <Tooltip title="Instagram">
                        <li>
                          <i className="fab fa-instagram"></i>
                        </li>
                      </Tooltip>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/cs-international-school-3672b1225"
                      target="_blank"
                    >
                      <Tooltip title="Linkedin">
                        <li>
                          <i className="fab fa-linkedin"></i>
                        </li>
                      </Tooltip>
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=uW85icjQqvw"
                      target="_blank"
                    >
                      <Tooltip title="Youtube">
                        <li>
                          <i className="fab fa-youtube"></i>
                        </li>
                      </Tooltip>
                    </a>
                    <a
                      href="https://twitter.com/csinternation10/status/1476538199233032192?s=12"
                      target="_blank"
                    >
                      <Tooltip title="Twitter">
                        <li>
                          <i className="fab fa-twitter"></i>
                        </li>
                      </Tooltip>
                    </a>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12 footer-info footer-col-2">
                <div className="footer-links">
                  <h4>QUICK LINKS</h4>
                  <ul>
                    <Link to="/">
                      <li>Home</li>
                    </Link>
                    <Link to="/learn-more">
                      <li>Learn More</li>
                    </Link>
                    <Link to="/academics">
                      <li>Academics</li>
                    </Link>
                    <Link to="/admissions">
                      <li>Admissions</li>
                    </Link>
                    <Link to="/life-at-csis">
                      <li>Life At CSIS</li>
                    </Link>
                    <Link to="/reach-us">
                      <li>Reach Us</li>
                    </Link>
                    <Link to="/mandatory-disclosure">
                      <li>Mandatory Disclosure</li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 footer-info footer-col-3">
                <div className="container news-media">
                  <div className="media-header">
                    <div>News and Media</div>
                  </div>
                  <div className="media-body">
                    <div className="media-item">
                      <div className="item-left">
                        <img
                          src="/assets/images/Newsletter/newsletter-1.png"
                          alt="Newsletter Image"
                          className="img-fluid"
                        />
                      </div>
                      <div className="item-right">
                        <div className="pb-2">
                          CS INTERNATIONAL NEWSLETTER - 1
                        </div>
                        <a
                          download={"Newsletter.pdf"}
                          target={"_blank"}
                          style={{ color: "#4154f1" }}
                          href="assets/pdf/newsletters_1.pdf"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    <div className="media-item">
                      <div className="item-left">
                        <img
                          src="/assets/images/Newsletter/newsletter-1.png"
                          alt="Newsletter Image"
                          className="img-fluid"
                        />
                      </div>
                      <div className="item-right">
                        <div className="pb-2">
                          CS INTERNATIONAL NEWSLETTER - 2
                        </div>
                        <a
                          download={"Newsletter.pdf"}
                          target={"_blank"}
                          style={{ color: "#4154f1" }}
                          href="assets/pdf/newsletters_2.pdf"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 footer-col-4 footer-facebook-box">
                <div className="footer-right-box mb-4 fb-page-container">
                  <div
                    className="fb-page"
                    data-href="https://www.facebook.com/csinternationalschool"
                    data-tabs="timeline"
                    data-width="300"
                    data-height="338"
                    data-small-header="true"
                    data-adapt-container-width="true"
                    data-hide-cover="false"
                    data-show-facepile="true"
                  >
                    <blockquote
                      cite="https://www.facebook.com/csinternationalschool"
                      className="fb-xfbml-parse-ignore"
                    >
                      <a href="https://www.facebook.com/csinternationalschool">
                        CS International School
                      </a>
                    </blockquote>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center mt-4"
                    style={{ color: "#012970" }}
                  >
                    {" "}
                    <strong>CBSE Affiliation Number</strong> : 831148{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="copyright">
              © Copyright{" "}
              <strong>
                <span>CS International School</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              Designed by{" "}
              <a href="https://twoandhalfdots.com" target="_blank">
                Two and Half Dots
              </a>
            </div>
          </div>
        </div>
        <a className="back-to-top">
          <i className="fas fa-arrow-up"></i>
        </a>
      </footer>
      <div id="preloader"></div>
    </>
  );
}

export default Footer;
