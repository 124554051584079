import React from 'react';

function Facility() {
    return (
        <div>
            <section className='values mt-3 mt-lg-5'>
            <div className="container" data-aos="fade-up" data-aos-offset="0">
                <div className="value-header">
                    <p>About Our School</p>
                </div>
                <div className="row">
                    <div className="col-lg-4" data-aos="fade-up" >
                        <div className="box">
                            <img src="/assets/images/Home-Page/Facility/technology.jpg" className='img-fluid' alt="Modern Teaching Technology" />
                            <h3>Modern Teaching Technology</h3>
                            <p>
                                With the use of modern teaching techniques such as Smart Boards, Computers, Projectors, Digital and Graphical Devices we create an atmosphere of creative yet informative learning experience which help students be motivated and interested towards learning.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
                        <div className="box">
                            <img src="/assets/images/Home-Page/Facility/infrastructure.jpg" className='img-fluid' alt="Excellent Infrastructure" />
                            <h3>Excellent Infrastructure</h3>
                            <p>
                                The school consists facilities of international standards such as: Swimming Pool, Skating Rink, Basket Ball Court, Cricket and Football Ground, Indoor Games Room to promote sports and physical education. An amphitheatre with digital system for lighting and sound for entertainment, art and culture. Library, Computer Lab, Science Lab, Smart Boards, Projectors for academics.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
                        <div className="box">
                            <img src="/assets/images/Home-Page/Facility/teachers.jpg" alt="Experienced Teachers" className='img-fluid' />
                            <h3>Experienced Teachers</h3>
                            <p>
                                Our teachers are highly experienced and motivated towards helping the students improve upon their interests be it academics, sports or art. With limited number of students per class the teachers will be able to focus on every student and have personal attention at all times.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>
    )
}

export default Facility;
