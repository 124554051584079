const Staff_Information = [
    {
        id:1,
        information:"PRINCIPAL",
        details:"MRS.DEEPIKA SHARMA"
    },
    {
        id:2,
        information:"TOTAL NO. OF TEACHERS",
        details:"28"
    },
    {
        id: null,
        information:"PGT",
        details:"6"
    },
    {
        id: null,
        information:"TGT",
        details:"14"
    },
    {
        id: null,
        information:"PRT",
        details:"08"
    },
    {
        id:3,
        information:"TEACHERS SECTION RATIO",
        details:"21:1"
    },
    {
        id:4,
        information:"DETAILS OF SPECIAL EDUCATOR",
        details:""
    },
    {
        id:5,
        information:"DETAILS OF COUNSELLOR AND WELNESS TEACHER",
        details:"MRS.MADHURA PRASAD"
    },
]

export default Staff_Information;