import React, { useState } from "react";
import { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import Carousel_item from "../../api/Carousel_item";
function CarouselSlider() {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(Carousel_item);
  }, []);
  return (
    <div className="my__carousel_main">
      <Carousel fade controls={false}>
        {data.map((item) => {
          return (
            <Carousel.Item key={item.id}>
              <img
                className="d-block w-100 carousel-img"
                src={`${item.source}`}
                alt={item.alt_name}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default CarouselSlider;
