const School_Infrastructure = [
    {
        id:1,
        information:"TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)",
        details:"6272.05"
    },
    {
        id:2, 
        information:"NO AND SIZE OF THE CLASS ROOM (IN SQ.MTR)",
        details:"25",
        details2:"564"
    },
    {
        id: 3,
        information:"NO AND SIZE OF LABORATIES INCLUDING COMPUTER LABS (IN SQ MTR)",
        details:"4",
        details2:"1008"
    },
    {
        id: 4,
        information:"INTENERT FACILITY",
        details:"YES"
    },
    {
        id: 5,
        information:"NO. OF GIRLS TOLETS",
        details:"22"
    },
    {
        id:6,
        information:"NO. OF BOYS TOLETS",
        details:"32"
    },
    {
        id:7,
        information:"LINK OF YOUTUBE VIDEO OF THE INSEPCTION SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL",
        details:null,
        link:"https://youtu.be/cOadiSjXj3o",
    },
]

export default School_Infrastructure;