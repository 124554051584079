import React from "react";
import { useEffect, useState } from "react";
import Gallery_item from "../api/Gallery_item";
import Gallery_pic from "../components/Gallery/Gallery_pic";

function Gallery() {
  const [pics, setPics] = useState([]);
    useEffect(() => {
      setPics(Gallery_item);
    }, []);
      
  if (!pics) {
    return <div>Loading....</div>;
  } else {
  return (
    <>
      <section className="Gallery">
        <div className="container text-center">
          <div className="Gallery-title mt-3">
            <h2>Life At CSIS</h2>
          </div>
          <div className="Gallery-content mt-3 mt-lg-5">
            <div className="row">
              {pics.map((item,index) => {
                return (
                    <Gallery_pic item={item} key={index} />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
}

export default Gallery;
