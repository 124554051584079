import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Staff_Information from "../../api/Staff_Information";

const StaffInformationTable = () => {
  const [StaffInfo, setStaffInfo] = useState([]);

  useEffect(() => {
    setStaffInfo(Staff_Information);
  }, [])

  if(!StaffInfo){
    return <>Loading...</>
  }
  else{
    return (
      <div className='mt-5'>
        <p className='mandatory-disclosure-subtitle'>STAFF (TEACHING)</p>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>SL.No.</th>
              <th>INFORMATION</th>
              <th>DETAILS</th>
            </tr>
          </thead>
          <tbody>
            {
              StaffInfo.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.information}</td>
                    <td>{item.details}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
}

export default StaffInformationTable;