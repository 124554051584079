 const Carousel_item = [
    {
        id:1,
        source:"assets/images/Home-Page/carousel/slide-1.jpg",
        alt_name:"First slide"
    },
    {
        id:2,
        source:"assets/images/Home-Page/carousel/slide-2.jpg",
        alt_name:"Second slide"
    },
    {
        id:3,
        source:"assets/images/Home-Page/carousel/slide-3.jpeg",
        alt_name:"Third slide"
    },
    {
        id:4,
        source:"assets/images/Home-Page/carousel/slide-4.jpeg",
        alt_name:"Fourth slide"
    },
    {
        id:5,
        source:"assets/images/Home-Page/carousel/slide-5.jpg",
        alt_name:"Fifth slide"
    },
    {
        id:6,
        source:"assets/images/Home-Page/carousel/slide-6.jpg",
        alt_name:"Sixth slide"
    },
    {
        id:7,
        source:"assets/images/Home-Page/carousel/slide-7.jpeg",
        alt_name:"Seventh slide"
    },
    {
        id:8,
        source:"assets/images/Home-Page/carousel/slide-8.jpeg",
        alt_name:"Eight slide"
    },
    {
        id:9,
        source:"assets/images/Home-Page/carousel/slide-9.jpeg",
        alt_name:"Ninth slide"
    },
]

export default Carousel_item;