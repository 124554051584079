import React from "react";
import Academics_slide from "../components/Academics/Academics_slide";

function Academics() {
  return (
    <>
      <section className="Academics description">
        <div className="container">
          <div className="Academics_title">
            <h2>ACADEMICS &amp; BEYOND</h2>
          </div>
          <div className="Academics_content">
            <Academics_slide />
          </div>
        </div>
      </section>
    </>
  );
}

export default Academics;
