import React from "react";

function Description() {
  return (
    <>
      <section className="description">
        <div className="container">
          <div className="description-header" data-aos="fade-up">
            <p>Holistic Development at CSIS</p>
          </div>
          <div className="row gx-0 description-row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="description-content">
                <div className="content">
                  <h2>Theme Based Curriculum</h2>
                  <p>
                    Balanced curriculum, NCERT, creativity, application,
                    practical aspects, celebrations, ready for life, teaching
                    aids, smart boards, computer labs, digitized library.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src="/assets/images/Home-Page/Description/theme-based-curriculum.jpg"
                className="img-fluid"
                alt="Description image"
              />
            </div>
          </div>
          <div className="row gx-0 flex-row-reverse description-row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="description-content">
                <div className="content">
                  <h2>Sporting &minus; Level Pro</h2>
                  <p>
                    Advanced sports amenities, mini Olympic swimming pool,
                    skating, karate, power yoga, coaching by certified
                    professional coaches. Annual sports event, inter school
                    competition. Awards.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src="/assets/images/Home-Page/Description/sports.jpg"
                className="img-fluid"
                alt="Description image"
              />
            </div>
          </div>
          <div className="row gx-0 description-row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="description-content">
                <div className="content">
                  <h2>Safety in Every Step</h2>
                  <p>
                    Female caretakers, care and concern at all times, infirmary,
                    CCTV coverage entire campus, Safety in transport , GPS
                    enabled with tracking. Digitized procedures, application
                    updates.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src="/assets/images/Home-Page/Description/safety.jpg"
                className="img-fluid"
                alt="Description image"
              />
            </div>
          </div>
          <div className="row gx-0 flex-row-reverse description-row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="description-content">
                <div className="content">
                  <h2>Enrichment Activities</h2>
                  <p>
                    Life skill development, student welfare council, community
                    integration, welfare activities, scholarship, inter school
                    programs, exhibitions, fests, blood donation and health
                    checkup camps, plantation drive, traffic awareness program.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src="assets/images/Home-Page/Description/enrichment.jpg"
                className="img-fluid"
                alt="Description image"
              />
            </div>
          </div>
          <div className="row gx-0 description-row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="description-content">
                <div className="content">
                  <h2>Parent Teacher Association</h2>
                  <p>
                    Transparent admission process, system of feedback,
                    approachable and availability, access to meet principal,
                    response time, parent concerns.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="300"
            >
              <img
                src="/assets/images/Home-Page/Description/parents-association.jpg"
                className="img-fluid"
                alt="Description image"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Description;
