import React, { useEffect } from "react";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import PageNotFound from "./components/PageNotFound";
import "./style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./components/Footer";
import Gallery from "./pages/Gallery";
import Admissions from "./pages/Admissions";
import Academics from "./pages/Academics";
import ReachUs from "./pages/ReachUs";
import MandatoryDisclosure from "./pages/MandatoryDisclosure";
import ScrollToTop from "./components/ScrollToTop";
import $ from "jquery";

function App() {
  useEffect(() => {
    $(function () {
      $(window).on("load", function () {
        $("#preloader").fadeOut("slow", function () {
          $(this).remove();
        });
      });
    });
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 50) {
        $(".back-to-top").addClass("d-flex");
      } else {
        $(".back-to-top").removeClass("d-flex");
      }
    });
    $(".back-to-top").on("click", function () {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        100
      );
      return false;
    });
    $(".nav-header")
      .find(".mobile-nav-toggler")
      .on("click", function () {
        $("nav#navbar").toggleClass("navbar-mobile");
        $("nav#navbar").find("i.mobile-nav-toggler").toggleClass("fa-bars");
        $("nav#navbar").find("i.mobile-nav-toggler").toggleClass("fa-times");
      });
    $(".mobile-nav-item").on("click", function () {
      $("nav#navbar").removeClass("navbar-mobile");
      $("nav#navbar").find("i.mobile-nav-toggler").removeClass("fa-times");
      $("nav#navbar").find("i.mobile-nav-toggler").addClass("fa-bars");
    });
    function aos_init() {
      AOS.init({
        duration: 1000,
        offset: 100,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    }
    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <NavBar />
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/learn-more" element={<AboutUs />} />
          <Route path="/academics" element={<Academics />} />
          <Route path="/admissions" element={<Admissions />} />
          <Route path="/life-at-csis" element={<Gallery />} />
          <Route path="/reach-us" element={<ReachUs />} />
          <Route path="/mandatory-disclosure" element={<MandatoryDisclosure />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
