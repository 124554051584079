const General_Information = [
    {
        id:1,
        information:"NAME OF THE SCHOOL",
        details:"CS INTERNATIONAL SCHOOL"
    },
    {
        id:2,
        information:"AFFILIATION NO. (IF APPLICABLE)",
        details:"831148"
    },
    {
        id:3,
        information:"SCHOOL CODE (IF APPLICABLE)",
        details:"46779"
    },
    {
        id:4,
        information:"COMPLETE ADDRESS WITH PIN CODE",
        details:"SY.NO 18, BILWARADAHALLI, GOTTIGERE POST, BANNERGHATTA ROAD, JIGANI HOBLI,ANEKAL TALUK BENGALURU-560083"
    },
    {
        id:5,
        information:"PRINCIPAL NAME",
        details:"MRS. DEEPIKA SHARMA"
    },
    {
        id:6,
        information:"PRINCIPAL QUALIFICATION",
        details:"M.A (ECONOMICS) B.Ed"
    },
    {
        id:7,
        information:"SCHOOL EMAIL ID",
        details:"csinternationalschool@gmail.com"
    },
    {
        id:8,
        information:"CONTACT DETAILS (LANDLINE/MOBILE)",
        details:"+91-7676326137"
    },
]

export default General_Information;