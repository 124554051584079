const Academics_Information = [
  {
    id: 1,
    information: "FEE STRUCTURE OF THE SCHOOL",
    link: "https://drive.google.com/file/d/1s33bLOzJ2_ydLOkBtLRBZjVuomnr8b3w/view?usp=sharing",
  },
  {
    id: 2,
    information: "ANNUAL ACADEMIC CALENDER",
    link: "https://drive.google.com/file/d/1LFjUrB3IhGGxtcobL4wMzTVdCQ4tWv4J/view?usp=sharing",
  },
  {
    id: 3,
    information: "LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)",
    link: "https://drive.google.com/file/d/1WvX3VznOXY9zRmAUckSOjI5zefYIsbxR/view",
  },
  {
    id: 4,
    information: "LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS",
    link: "https://drive.google.com/file/d/1tkYU-VXRh2K6uhuJzVjvdWAUT3ymHEUG/view",
  },
  {
    id: 5,
    information:
      "LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY",
    link: "https://drive.google.com/file/d/182r6MocDwbLv9_46eANXgjjpB0xZIPqY/view?usp=sharing",
  },
];

export default Academics_Information;
