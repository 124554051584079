import React from "react";
import CarouselSlider from "../components/Home/Carousel";
import Description from "../components/Home/Description";
import Facility from "../components/Home/Facility";
import WhyCSIS from "../components/Home/WhyCSIS";
import { HomePopup } from "../components/Home/HomePopup";

function Home() {
  return (
    <>
      <HomePopup />
      <CarouselSlider />
      <Facility />
      <WhyCSIS />
      <Description />
    </>
  );
}

export default Home;
