import React,{useState} from 'react';
import emailjs from "emailjs-com";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SendMail() {
    const initialFormData =  {
        name:'',
        email:'',
        subject:'',
        message:'',
      }
      
        const [formData,setFormData] = useState(initialFormData);
        const [isFormSubmitted, setIsFormSubmitted] = useState(false);
        const [formError,setFormError] = useState(null);
        const successToastIcon = <i className="far fa-check-circle" />;
        const failureToastIcon = <i class="fas fa-exclamation-circle" />;

        const handleData = (e) => {
          setFormData({
            ...formData,
            [e.target.id] : e.target.value,
          });
        }
    
        const handleValidate = () => {
          let error = false;
            if(formData.name === ''){
              error = {
                field_id:'name',
                errorMessage:'Please fill this field',
              };
              return error;
            }
    
            if(formData.email === ''){
              error = {
                field_id:'email',
                errorMessage:'Please fill this field',
              }
              return error;
            }
            
            if(formData.subject === ''){
              error = {
                field_id:'subject',
                errorMessage:'Please fill this field',
              };
              return error;
            }
            
            if(formData.message === ''){
              error = {
                field_id:'message',
                errorMessage:'Please fill this field',
              };
              return error;
            }
            return error;
        }
    
    const submitContactData = (e) => {
        e.preventDefault();
        setFormError(null);
        let error = handleValidate();
        if(error){
            setFormError(error);
            return;
        }
        setIsFormSubmitted(true);
        emailjs
          .sendForm(
            "service_xjzvvio",
            "template_wx4iazo",
            e.target,
            "user_w48GUG6YlyS2Su4NBeRQF"
          )
          .then((res) => {
            setIsFormSubmitted(false);
            toast.success("Message sent successfully", {
                icon: successToastIcon,
                theme:"colored"
            });
          })
          .catch((error) => {
            setIsFormSubmitted(false);
            toast.error("Failed to send mail", {
            icon: failureToastIcon,
            theme:"colored"
            });
          });
          setFormData(initialFormData);
      };
    return (
        <>
            <form onSubmit={(e)=>submitContactData(e)} className='contact-form'>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <input type="text" onChange={(e)=>handleData(e)} value={formData.name} name="name" className="form-control name-input" id="name" placeholder="Your Name" />
                        {formError && formError.field_id === 'name' ? 
                            <span className="error_field_span">{formError.errorMessage}</span>
                        :null}
                    </div>
                    <div className="col-md-6 form-group">
                        <input type="email" onChange={(e)=>handleData(e)} value={formData.email} className="form-control email-input" name="email" id="email" placeholder="Your Email" required="" />
                        {formError && formError.field_id === 'email' ? 
                            <span className="error_field_span">{formError.errorMessage}</span>
                        :null}
                    </div>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control subject-input" onChange={(e)=>handleData(e)} value={formData.subject} name="subject" id="subject" placeholder="Subject" />
                {formError && formError.field_id === 'subject' ? 
                    <span className="error_field_span">{formError.errorMessage}</span>
                :null}
                </div>
                <div className="form-group">
                    <textarea className="form-control message-input" onChange={(e)=>handleData(e)} value={formData.message} name="message" id="message" rows="5" placeholder="Message"></textarea>
                    {formError && formError.field_id === 'message' ? 
                        <span className="error_field_span">{formError.errorMessage}</span>
                    :null}
                </div>
                <div className="text-center p-2">
                    <button type="submit">{isFormSubmitted == true ? 'Sending...' : 'Send Message'}</button>
                </div>
            </form>
        </>
    );
}

export default SendMail;
