import React from "react";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function NavBar() {
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 50) {
      $(".nav-header").addClass("fixed-top");
    } else {
      $(".nav-header").removeClass("fixed-top");
    }
  });
  return (
    <section className="navBar-scroll nav-header">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <Link to="/">
          <div className="logo d-flex">
            <img
              className="logo-img"
              src="/assets/images/logo.png"
              alt="logo image"
            />
            <div className="logo-brand d-flex flex-column justify-content-center">
              <span className="Business-name">CS INTERNATIONAL SCHOOL</span>
              <span className="Business-tagline text-center pr-3">
                EVOLVE.EMPOWER.ENLIGHTEN
              </span>
            </div>
          </div>
        </Link>
        <nav id="navbar" className="nav-menu">
          <ul>
            <NavLink to="/" className="nav-item">
              <li className="mobile-nav-item">
                <i
                  className="fas fa-home"
                  style={{ fontSize: "25px", paddingTop: "4px" }}
                ></i>
              </li>
            </NavLink>
            <NavLink to="/learn-more" className="nav-item">
              <li className="mobile-nav-item">Learn More</li>
            </NavLink>
            <NavLink to="/academics" className="nav-item">
              <li className="mobile-nav-item">Academics</li>
            </NavLink>
            <NavLink to="/admissions" className="nav-item">
              <li className="mobile-nav-item">Admissions</li>
            </NavLink>
            <NavLink to="/life-at-csis" className="nav-item">
              <li className="mobile-nav-item">Life At CSIS</li>
            </NavLink>
            <NavLink to="/reach-us" className="nav-item">
              <li className="mobile-nav-item">Reach Us</li>
            </NavLink>
            <NavLink to="/mandatory-disclosure" className="nav-item">
              <li className="mobile-nav-item">Mandatory Disclosure</li>
            </NavLink>
            <a href="https://corp30.myclassboard.com/" target={"_blank"}>
              <li className="nav-item login mobile-nav-item">Login</li>
            </a>
          </ul>
          <i className="fas fa-bars mobile-nav-toggler"></i>
        </nav>
      </div>
      <ToastContainer />
    </section>
  );
}

export default NavBar;
