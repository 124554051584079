const Gallery_item = [
  { 
    id: 1,
    name: "image 1",
    image: "assets/images/Gallery/gallery-1.jpg",
    category: "one",
  },
  {
    id: 2,
    name: "image 2",
    image: "assets/images/Gallery/gallery-2.jpg",
    category: "one",
  },
  {
    id: 3,
    name: "image 3",
    image: "assets/images/Gallery/gallery-3.jpg",
    category: "one",
  },
  {
    id: 4,
    name: "image 4",
    image: "assets/images/Gallery/gallery-4.jpg",
    category: "one",
  },
  {
    id: 5,
    name: "image 5",
    image: "assets/images/Gallery/gallery-5.jpg",
    category: "one",
  },
  {
    id: 6,
    name: "image 6",
    image: "assets/images/Gallery/gallery-6.jpg",
    category: "one",
  },
  {
    id: 7,
    name: "image 7",
    image: "assets/images/Gallery/gallery-7.jpg",
    category: "one",
  },
  {
    id: 8,
    name: "image 8",
    image: "assets/images/Gallery/gallery-8.jpg",
    category: "one",
  },
  {
    id: 9,
    name: "image 9",
    image: "assets/images/Gallery/gallery-9.jpg",
    category: "one",
  },
  {
    id: 10,
    name: "image 10",
    image: "assets/images/Gallery/gallery-10.jpg",
    category: "one",
  },
  {
    id: 11,
    name: "image 11",
    image: "assets/images/Gallery/gallery-11.jpg",
    category: "one",
  },
  {
    id: 12,
    name: "image 12",
    image: "assets/images/Gallery/gallery-12.jpg",
    category: "one",
  },
  {
    id: 13,
    name: "image 13",
    image: "assets/images/Gallery/gallery-13.jpg",
    category: "one",
  },
  {
    id: 14,
    name: "image 14",
    image: "assets/images/Gallery/gallery-14.jpg",
    category: "one",
  },
  {
    id: 15,
    name: "image 15",
    image: "assets/images/Gallery/gallery-15.jpg",
    category: "one",
  },
  {
    id: 16,
    name: "image 16",
    image: "assets/images/Gallery/gallery-16.jpg",
    category: "one",
  },
  {
    id: 17,
    name: "image 17",
    image: "assets/images/Gallery/gallery-17.jpg",
    category: "one",
  },
  {
    id: 18,
    name: "image 18",
    image: "assets/images/Gallery/gallery-18.jpg",
    category: "one",
  },
  {
    id: 19,
    name: "image 19",
    image: "assets/images/Gallery/gallery-19.jpg",
    category: "one",
  },
  {
    id: 20,
    name: "image 20",
    image: "assets/images/Gallery/gallery-20.jpg",
    category: "one",
  },
  {
    id: 21,
    name: "image 21",
    image: "assets/images/Gallery/gallery-21.jpg",
    category: "one",
  },
  {
    id: 22,
    name: "image 22",
    image: "assets/images/Gallery/gallery-22.jpg",
    category: "one",
  },
  {
    id: 23,
    name: "image 23",
    image: "assets/images/Gallery/gallery-23.jpeg",
    category: "one",
  },
  {
    id: 24,
    name: "image 24",
    image: "assets/images/Gallery/gallery-24.jpg",
    category: "one",
  },
  {
    id: 25,
    name: "image 25",
    image: "assets/images/Gallery/gallery-25.jpg",
    category: "one",
  },
  {
    id: 26,
    name: "image 26",
    image: "assets/images/Gallery/gallery-26.jpg",
    category: "one",
  },
  {
    id: 27,
    name: "image 27",
    image: "assets/images/Gallery/gallery-27.jpg",
    category: "one",
  },
  {
    id: 28,
    name: "image 28",
    image: "assets/images/Gallery/gallery-28.jpg",
    category: "one",
  },
  {
    id: 29,
    name: "image 29",
    image: "assets/images/Gallery/gallery-29.jpg",
    category: "one",
  },
  {
    id: 30,
    name: "image 30",
    image: "assets/images/Gallery/gallery-30.jpg",
    category: "one",
  },
];

export default Gallery_item;
