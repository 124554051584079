import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import School_Infrastructure from "../../api/School_Infrastructure";

const SchoolInfrastructureTable = () => {
  const [SchoolInfrastructure, setSchoolInfrastructure] = useState([]);

  useEffect(() => {
    setSchoolInfrastructure(School_Infrastructure);
  }, [])

  if(!SchoolInfrastructure){
    return <>Loading...</>
  }
  else{
    return (
      <div className='mt-5'>
        <p className='mandatory-disclosure-subtitle'>SCHOOL INFRASTRUCTURE </p>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>SL.No.</th>
              <th>INFORMATION</th>
              <th>DETAILS</th>
            </tr>
          </thead> 
          <tbody>
            {
              SchoolInfrastructure.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.information}</td>
                    { (item.details != null) ? (<td>{item.details} { (item.details2 != null) ? (<>&amp; {item.details2}</>) : <></>}</td>) : <td><a href={item.link} target={"_blank"} >View</a></td>}
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
}

export default SchoolInfrastructureTable;