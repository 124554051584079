import React, { useState } from "react";
import SendMail from "../components/ReachUs/SendMail";
import Alert from "react-bootstrap/Alert";

function ReachUs() {
  const [showAlert, setShowAlert] = new useState(true);
  const handleAlert = () => {
    setShowAlert(false);
  };
  return (
    <>
      <section className="ReachUS">
        <div className="container">
          {showAlert && (
            <Alert className="we-are-hiring" dismissible onClose={handleAlert}>
              <i className="fa fa-bell"></i>&nbsp;&nbsp;
              <strong>We are Hiring!</strong>&nbsp;Looking for people who have
              the drive and talent to help us reach new heights.
            </Alert>
          )}
          <div className="ReachUS-title mb-4">
            <h2>REACH US</h2>
          </div>
          <div className="google-map" data-aos="fade-up">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15560.618374515232!2d77.5728583!3d12.833285!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbcd24c6a4a66796d!2sCS%20International%20School!5e0!3m2!1sen!2sin!4v1642010136643!5m2!1sen!2sin"
              width="100%"
              height="320"
              style={{ border: "0" }}
              loading="lazy"
            ></iframe>
          </div>
          <div className="ReachUS-content" data-aos="fade-up">
            <div className="row mt-3 mt-lg-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="fas fa-map-marker-alt"></i>
                    <h4>Location:</h4>
                    <p>
                      18 Bilwaradahali, Near Area 83 Bannerghatta Road,
                      Bangalore &minus; 560 083
                    </p>
                  </div>
                  <div className="email">
                    <i className="far fa-envelope"></i>
                    <h4>Email:</h4>
                    <p>csinternationalschool.edu@gmail.com</p>
                  </div>
                  <div className="phone">
                    <i className="fas fa-phone-alt"></i>
                    <h4>Call:</h4>
                    <p>18001237191</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 p-2">
                <SendMail />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
// (
//     <div
//       className="alert alert-primary alert-dismissible fade show we-are-hiring"
//       role="alert"
//     >
//       <i className="fa fa-bell"></i>&nbsp;&nbsp;
//       <strong>We are Hiring!</strong>&nbsp;Looking for people who have
//       the drive and talent to help us reach new heights.
//       <button
//         type="button"
//         className="close we-are-hiring-dismiss-btn"
//         onClick={handleAlert}
//       >
//         <span aria-hidden="true">&times;</span>
//       </button>
//     </div>
//   )
export default ReachUs;
