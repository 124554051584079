import React from "react";

function WhyCSIS() {
  return (
    <>
      <section className="mt-3 mt-lg-4">
        <div className="WhyCSIS-wrapper">
          <div className="WhyCSIS-wrapper-header"  data-aos="fade-up">
            <p>Why Choose CSIS</p>
          </div>
          <div className="WhyCSIS-wrapper-body">
            <div className="row">
              <div className="col-lg-4 col-md-6  WhyCSIS-left-block">
                <ul>
                <li data-aos="fade-right" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-content-left-wrap WhyCSIS-content-wrap">
                      <span>Smart Teaching Technology</span>
                    </div>
                    <div className="WhyCSIS-image-left-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/teaching.png" className="img-fluid" alt="Why CSIS image-2" /> 
                      </div>
                    </div>
                  </li>
                  <li data-aos="fade-right" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-content-left-wrap WhyCSIS-content-wrap">
                      <span>Excellent Infrastructure</span>
                    </div>
                    <div className="WhyCSIS-image-left-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/school.png" className="img-fluid"  alt="Why CSIS image-3" />
                      </div>
                    </div>
                  </li>
                  <li data-aos="fade-right" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-content-left-wrap WhyCSIS-content-wrap">
                      <span>Well Equipped Labs &amp; Library</span>
                    </div>
                    <div className="WhyCSIS-image-left-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/library.png" className="img-fluid" alt="Why CSIS image-4" /> 
                      </div>
                    </div>
                  </li>
                  <li data-aos="fade-right" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-content-left-wrap WhyCSIS-content-wrap">
                      <span>Pollution Free Ambience</span>
                    </div>
                    <div className="WhyCSIS-image-left-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/polution.png" className="img-fluid" alt="Why CSIS image-1" /> 
                      </div>
                    </div>
                  </li>
                  <li data-aos="fade-right" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-content-left-wrap WhyCSIS-content-wrap">
                      <span>In-Campus Infirmary</span>
                    </div>
                    <div className="WhyCSIS-image-left-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/infirmary.png"  alt="Why CSIS image-5" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 WhyCSIS-middle-block" data-aos="zoom-out" data-aos-delay="300">
                <img
                  src="/assets/images/Home-Page/WhyCSIS/student.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-4 col-md-6 WhyCSIS-right-block">
              <ul>
                    <li data-aos="fade-left" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-image-right-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/digitised.png"  alt="Why CSIS image-9" />
                      </div>
                    </div>
                    <div className="WhyCSIS-content-right-wrap WhyCSIS-content-wrap">
                      <span>Digitized procedures</span>
                    </div>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-image-right-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/sports.png"  alt="Why CSIS image-7" />
                      </div>
                    </div>
                    <div className="WhyCSIS-content-right-wrap WhyCSIS-content-wrap">
                      <span>Professional Sports Training</span>
                    </div>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-image-right-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/enrichment.png"  alt="Why CSIS image-8" /> 
                      </div>
                    </div>
                    <div className="WhyCSIS-content-right-wrap WhyCSIS-content-wrap">
                      <span>Enrichment Activities</span>
                    </div>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-image-right-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                      <img src="/assets/images/Home-Page/WhyCSIS/safety.png"  alt="Why CSIS image-10" />
                      </div>
                    </div>
                    <div className="WhyCSIS-content-right-wrap WhyCSIS-content-wrap">
                      <span>Safety in Every Step</span>
                    </div>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="200" data-aos-offset="0">
                    <div className="WhyCSIS-image-right-wrap WhyCSIS-image-wrap">
                      <div className="WhyCSIS-image-wrap-bg">
                        <img src="/assets/images/Home-Page/WhyCSIS/curiculum.png" alt="Why CSIS image-6" />
                      </div>
                    </div>
                    <div className="WhyCSIS-content-right-wrap WhyCSIS-content-wrap">
                      <span>Theme based curriculum</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyCSIS;
