import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import ResultXIIth from "../../api/ResultXIIth";

const ResultsXIIthTable = () => {
  const [ResultXIIthData, SetResultXIIthData] = useState([]);

  useEffect(() => {
    SetResultXIIthData(ResultXIIth);
  }, [])

  if(!ResultXIIthData){
    return <>Loading...</>
  }
  else{
    return (
      <div className='mt-5'>
        <p className='mandatory-disclosure-subtitle'>Result Class : XII</p>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>SL.No.</th>
              <th>YEAR</th>
              <th>NO. OF REGISTERED STUDENTS</th>
              <th>NO. OF STUDENTS</th>
              <th>PASS PERCENTAGE</th>
              <th>REMARKS</th>
            </tr>
          </thead>
          <tbody>
            {
              ResultXIIthData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.year}</td>
                    <td>{item.registered_students}</td>
                    <td>{item.students}</td>
                    <td>{item.pass_percentage}</td>
                    <td>{item.remarks}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
}

export default ResultsXIIthTable;