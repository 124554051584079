import React from "react";
import Tooltip from "@mui/material/Tooltip";
import {Link} from "react-router-dom";

function Header() {
  return (
    <>
      <div className="header d-flex">
        <div className="header-email">
          <a href="mailto:csinternationalschool.edu@gmail.com" target="_blank">
            csinternationalschool.edu@gmail.com
          </a>
        </div>
        <div className="header-phone">
            <span className="toll-free">Toll Free </span>
            <span>18001237191</span>
        </div>
        <div className="header-socialMedia">
            <ul>
                <a href="https://www.facebook.com/csinternationalschool" target="_blank">
                  <Tooltip title="Facebook"><li><i className="fab fa-facebook"></i></li></Tooltip>
                </a>
                <a href="https://www.instagram.com/csinternationalschool/" target="_blank">
                  <Tooltip title="Instagram"><li><i className="fab fa-instagram"></i></li></Tooltip>
                </a>
                <a href="https://www.linkedin.com/in/cs-international-school-3672b1225" target="_blank">
                  <Tooltip title="Linkedin"><li><i className="fab fa-linkedin"></i></li></Tooltip>
                </a>
                <a href="https://www.youtube.com/watch?v=uW85icjQqvw" target="_blank">
                  <Tooltip title="Youtube"><li><i className="fab fa-youtube"></i></li></Tooltip>
                </a>
                <a href="https://twitter.com/csinternation10/status/1476538199233032192?s=12" target="_blank"> 
                  <Tooltip title="Twitter"><li><i className="fab fa-twitter"></i></li></Tooltip>
                </a>
            </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
