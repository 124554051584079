import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

export const FeeStructurePopup = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  $(document).ready(function () {
    var key = "admission_modal";
    var isModalShowed = sessionStorage.getItem(key);
    if (!isModalShowed) {
      setTimeout(function () {
        handleShow();
      }, 500);
      sessionStorage.setItem(key, true);
    }
  });
  return (
    <Modal show={show} onHide={handleClose} className="admission_modal">
      <span onClick={handleClose} className="modal_close">
        &times;
      </span>
      <Modal.Header
        style={{ padding: "0px", height: "400px" }}
        className="modal_header"
      >
        <object
          data="assets/pdf/fee_structure_2023_24.pdf"
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p className="fee_structure_object">
            <strong>Fee Structure 2023-24</strong>
            <br />
            <span>
              Your web browser doesn't have a PDF plugin. <br />
              <br />
            </span>
            <a href="assets/pdf/fee_structure_2023_24.pdf">
              click here to download the PDF file.
            </a>
          </p>
        </object>
      </Modal.Header>
    </Modal>
  );
};
