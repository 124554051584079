import React from 'react';
import {NavLink} from "react-router-dom";

function PageNotFound() {
    return (
        <div className='row Page-404-container d-flex mt-3 align-items-center justify-content-around'>
            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
                <img className="Page-404-leftContainer img-fluid" src="/assets/images/404.png" alt="404Error" />
            </div>
            <div className="col-lg-6 mt-4 Page-404-rightContainer d-flex flex-column justify-content-center align-items-center">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <span>We're sorry, the page you requested could not be found</span>
                <span>Please go back to the homepage</span>
                <NavLink to="/">
                    <div className='go-home d-flex justify-content-center align-items-center'>
                        <span>GO HOME</span>
                        <i className="fas fa-arrow-right"></i>
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default PageNotFound;
