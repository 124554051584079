const ResultXth = [
    {
        id: 1,
        year: "2023",
        registered_students: "14",
        students: "14",
        pass_percentage: "100",
        remarks: "",
    }
]

export default ResultXth;