import React from 'react';
import GeneralInformationTable from "../components/MandatoryDisclosure/GeneralInformationTable";
import DocumentsInformationTable from "../components/MandatoryDisclosure/DocumentsInformationTable";
import AcademicsInformationTable from "../components/MandatoryDisclosure/AcademicsInformationTable";
import StaffInformationTable from "../components/MandatoryDisclosure/StaffInformationTable";
import ResultsXthTable from "../components/MandatoryDisclosure/ResultsXthTable";
import ResultsXIIthTable from "../components/MandatoryDisclosure/ResultsXIIthTable";
import SchoolInfrastructureTable from "../components/MandatoryDisclosure/SchoolInfrastructureTable";

const MandatoryDisclosure = () => {
  return (  
    <>
    <section className='mandatory-disclosure'>
        <div className="container text-center">
          <div className="mandatory-disclosure-title">
            <h2>Mandatory Disclosure</h2>
          </div>
        </div>
      <GeneralInformationTable />
      <DocumentsInformationTable />
      <AcademicsInformationTable />
      <StaffInformationTable />
      <ResultsXthTable />
      <ResultsXIIthTable />
      <SchoolInfrastructureTable />
    </section>
    </>
  )
}

export default MandatoryDisclosure;