import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../style.css";
import { NavLink } from "react-router-dom";
import $ from "jquery";

export const HomePopup = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  $(document).ready(function () {
    var key = "HomeModal";
    var isModalShowed = sessionStorage.getItem(key);
    if (!isModalShowed) {
      setTimeout(function () {
        handleShow();
      }, 1500);
      sessionStorage.setItem(key, true);
    }
  });
  return (
    <Modal show={show} onHide={handleClose} className="home_modal">
      <span onClick={handleClose} className="modal_close">
        &times;
      </span>
      <Modal.Header className="modal_header">
        <NavLink to="/admissions">
          <img
            src="/assets/images/admission_open.png"
            className="img-fluid"
          ></img>
        </NavLink>
      </Modal.Header>
    </Modal>
  );
};
