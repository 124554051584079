const Documents_Information = [
  {
    id: 1,
    information:
      "COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY",
    link: "https://drive.google.com/file/d/14uyi0rUvWGeCirSXahab1BCi5b0x7EsJ/view",
  },
  {
    id: 2,
    information:
      "COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE",
    link: "https://drive.google.com/file/d/1Br5I9-C4vkp184mJ34r5yl_JKtJIGbht/view?usp=sharing",
  },
  {
    id: 3,
    information:
      "COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT",
    link: "https://drive.google.com/file/d/1N_e6Vx8LGHFXtX9y9_i7EwQhQC9rxgZ9/view?usp=sharing",
  },
  {
    id: 4,
    information:
      "COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE",
    link: "https://drive.google.com/file/d/1eLjHt_u3bl3p-qREBbBrCnRW71cjTIfX/view?usp=sharing",
  },
  {
    id: 5,
    information:
      "COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE*",
    link: "https://drive.google.com/file/d/1kSxZAfY7ntrRrzK1IAsDS3TPvwsdllpF/view?usp=sharing",
  },
  {
    id: 6,
    information:
      "COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY*",
    link: "https://drive.google.com/file/d/1DEy48gPsLTXKjvli4jXresCxBhtvVY9Q/view?usp=sharing",
  },
  {
    id: 7,
    information:
      "COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL",
    link: "https://drive.google.com/file/d/1W4VlZRkoAsM4WNKQf532guURprdTzc8s/view",
  },
  {
    id: 8,
    information: "COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES",
    link: "https://drive.google.com/file/d/1xCpReduwrxVhpgol_P0RwvTy0AZCJ1vY/view?usp=sharing",
  },
];

export default Documents_Information;
