const ResultXIIth = [
    {
        id: "",
        year: "",
        registered_students: "",
        students: "",
        pass_percentage: "",
        remarks: "",
    }
]

export default ResultXIIth;