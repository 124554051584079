import React from 'react';
import AboutCSIS from "../components/AboutUS/AboutCSIS";

function AboutUs() {
    return (
        <>
            <AboutCSIS />
        </>
    )
}

export default AboutUs;
