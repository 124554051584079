import React from "react";
import { FeeStructurePopup } from "../components/Admissions/FeeStructurePopup";

function Admissions() {
  const ACADEMIC_MONTH = 5;
  return (
    <>
      <FeeStructurePopup />
      <section className="Admission">
        <div className="container">
          <div className="Admission-title mb-4 d-flex justify-content-end">
            <h2 style={{ textAlign: "center", margin: "12px auto" }}>
              ADMISSIONS
            </h2>
            <a
              href="https://corp30.myclassboard.com/ApplicationForm_Custom/82827550-B19B-4BC3-88F1-922CB984858E/1/0"
              target={"_blank"}
              className="apply-now"
            >
              Apply Now
            </a>
          </div>
          <div className="Admission-content">
            <div className="row">
              <div className="col-lg-6 p-4">
                <div className="Admission-item">
                  <h2>
                    ADMISSIONS OPEN FOR ACADEMIC YEAR{" "}
                    {new Date().getFullYear() +
                      (new Date().getMonth() >= ACADEMIC_MONTH ? 1 : 0)}
                    -
                    {(
                      new Date().getFullYear() +
                      (new Date().getMonth() >= ACADEMIC_MONTH ? 2 : 1)
                    )
                      .toString()
                      .substr(-2)}{" "}
                    FOR:
                  </h2>
                  <ul>
                    <li>PRE-KG , LKG AND UKG</li>
                    <li>GRADE - 1 to GRADE - 9</li>
                  </ul>
                </div>
                <div className="Admission-item Admission-item-2">
                  <h2>ADMISSION PROCEDURE:</h2>
                  <ul>
                    <li>
                      <span>Admission Form:</span>&nbsp;Parents can collect the
                      admission forms at the School Office or download the
                      application form online.
                    </li>
                    <li>
                      <span>Details Required:</span>&nbsp;All necessary details
                      of the Student and Parents are required to be filled as
                      mentioned in the application form.
                    </li>
                    <li>
                      <span>Documents Required:</span>&nbsp;Birth Certificate,
                      Aadhaar Card (optional), Transfer Certificate (if
                      applicable), Local address proof, Caste
                      Certificate(optional) and passport size photograph.
                    </li>
                    <li>
                      <span>Fee Structure:</span>&nbsp;The fee is payable
                      quarterly i.e., in 3 instalments. Kindly contact the
                      school administration for fee details.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src="/assets/images/Gallery/gallery-23.jpeg"
                  className="img-fluid"
                  style={{
                    height: "100%",
                    paddingTop: "20px",
                    paddingBottom: "40px",
                  }}
                  alt="Admisson image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Admissions;
//
