import React from "react";
import { ZoomOutMap } from '@material-ui/icons';

function Gallery_pic({ item }) { 
  return (
    <>
      <div
        className="col-lg-4 col-md-6 Gallery-item"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-offset="0"
      >
        <div className="Gallery-wrap">
          <img src={`/${item.image}`} className="img-fluid" alt={item.name} />
          <div className="overlay">
            <a
              href={item.image}
              className="fancybox overlay-icon"
              data-fancybox="Gallery"
            >
            <ZoomOutMap />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery_pic;
