import React from "react";

function AboutCSIS() {
  return (
    <>
      <section className="AboutCSIS">
        <div className="container">
          <div className="about-title" data-aos="fade-up">
            <h2>Learn More</h2>
            <p>CS INTERNATIONAL SCHOOL</p>
          </div>
          <div className="about-content">
            <div className="row">
              <div className="col-lg-6 column-first">
                <div className="mb-3"  data-aos="fade-up" data-aos-delay="200" style={{textAlign:'justify'}}>
                  A venerated visionary and philanthropist successful in all his
                  ventures throughout his career, Mr.Chandrashekar is an
                  absolute leader with clear vision and prescience. A simple
                  living and humble human being dedicated to serving the society
                  in every possible way. His abundant knowledge and guidance
                  will lead us to create an ebullient learning experience.
                </div>
                <div  data-aos="fade-up" data-aos-delay="400">
                <span>THE SCHOOL</span>
                <p style={{textAlign:'justify'}}>
                  CS International school is an institution formed under
                  Chaitrashree Foundation Educational &amp; Charitable Trust (Regd)
                  the first of its kind, dedicated to advancing education
                  through creative and interactive methods. We are devoted to
                  creating an atmosphere of learning with a focus on the
                  all-round development of the child.
                </p>
                </div>
                <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="0">
                <span>MISSION</span>
                <div style={{textAlign:'justify'}}>
                  <ul>
                    <li>Provide affordable quality education to all.</li>
                    <li>
                      Identify and enhance individual talent in every child.
                    </li>
                    <li>
                      Create a path for the children to shape their future.
                    </li>
                    <li>Focus on the overall development of the child.</li>
                    <li>Impart a sense of discipline and righteousness.</li>
                  </ul>
                </div>
                </div>
              </div>
              <div className="col-lg-6"  data-aos="zoom-out" data-aos-delay="200">
                <img
                  src="/assets/images/About/about.jpg"
                  alt="about image"
                  className="img-fluid"
                  style={{height:"100%",paddingBottom:"20px",paddingTop:'5px',borderRadius: '5px'}}
                />
              </div>
            </div>
            <div className="row" data-aos="fade-up" data-aos-offset="0">
            <div className="about-vision">
              <span>VISION</span>
                <p style={{textAlign:'justify'}}>
                  Our school aspires to be a centre of unmatched excellence by
                  creating an atmosphere of learning with a focus on all round
                  development of the child.
                </p>
              </div>
            </div>
            <marquee direction="left" className="marquee" data-aos="fade-in" data-aos-offset="0">
            “Education is not the learning of facts, but the training of the mind to think” – Albert Einstein.
            </marquee>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutCSIS;
