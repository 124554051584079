import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import ResultXth from "../../api/ResultXth";

const ResultsXthTable = () => {
  const [ResultXthData, SetResultXthData] = useState([]);

  useEffect(() => {
    SetResultXthData(ResultXth);
  }, [])

  if(!ResultXthData){
    return <>Loading...</>
  }
  else{
    return (
      <div className='mt-5'>
        <p className='mandatory-disclosure-subtitle'>Result Class : X</p>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>SL.No.</th>
              <th>YEAR</th>
              <th>NO. OF REGISTERED STUDENTS</th>
              <th>NO. OF STUDENTS</th>
              <th>PASS PERCENTAGE</th>
              <th>REMARKS</th>
            </tr>
          </thead>
          <tbody>
            {
              ResultXthData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.year}</td>
                    <td>{item.registered_students}</td>
                    <td>{item.students}</td>
                    <td>{item.pass_percentage}</td>
                    <td>{item.remarks}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
}

export default ResultsXthTable;