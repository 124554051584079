import React from 'react';

function Academics_slide() {
    return (
        <>  
        <div className="row gx-0 description-row">
                <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                    <div className="description-content" >
                        <div className='content' style={{padding:"10px 20px"}}>
                            <h2>Campus Facilities</h2>
                            <div>
                            <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Spacious campus, pollution free surroundings.</p></span>
                            <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Spacious Class Rooms with audio visual systems from Per- KG to upwards.</p></span>
                            <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Science Laboratories.</p></span>
                            <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Computer Laboratories(Junior and senior).</p></span>
                            <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Library (Junior and senior).</p></span>
                            <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Audiovisual room.</p></span>
                            <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Amphitheatre.</p></span>
                            <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Variety of Sport facilitieses.</p></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center"  data-aos="zoom-out" data-aos-delay="200">
                    <img src="/assets/images/Academics/campus.jpeg" className='img-fluid Academics-img' alt="Description image" />
                </div>
            </div>
            <div className="row gx-0 flex-row-reverse description-row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div className="description-content">
                                <div className='content' style={{padding:"10px 20px",lineHeight:'2.2'}}>
                                <h2>Teaching Methodology</h2>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Modern methods of teaching and learning using smart boards, projectors and computers.</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Use of themes and projects to develop higher order thinking and stimulate creativity.</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Large number of co-scholastic activities that provide wide and varied exposure.</p></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center"  data-aos="zoom-out" data-aos-delay="200">
                            <img src="/assets/images/Academics/teaching-methodolgy.jpeg" className='img-fluid' alt="Description image" />
                        </div>
                    </div>
                    <div className="row gx-0 description-row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div className="description-content">
                                <div className='content' style={{padding:"10px 20px"}}>
                                <h2>Sports</h2>
                                <p>Sports and physical Education form an integral part of our school curriculum, the school is committed to providing students with a variety of sporting facilities:</p>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Skating Rink</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Basket Ball Court</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Swimming pool</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Indoor Games</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Vast Play Ground</p></span>
                                 <p>Coaching and training will be provided within the school by in house coaches.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center"  data-aos="zoom-out" data-aos-delay="200">
                            <img src="/assets/images/Academics/sports.jpeg" className='img-fluid Academics-img' alt="Description image" />
                        </div>
                    </div>
                    <div className="row gx-0 flex-row-reverse description-row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div className="description-content">
                                <div className='content' style={{padding:"10px 20px",lineHeight:'1.8'}}>
                                <h2>Co-Scholastic</h2>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>The co-scholastic segment includes Indian Music, Dance, Art and Yoga and Shlokas.</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Cultural academy classes will be held every week.</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>Co-scholastic activities are showcased at the Annual day functions, Interschool Competitions and Annual Sports Day.</p></span>
                                 <span className='d-flex align-items-center'><i className="fas fa-check-circle"></i><p>The school houses a spacious amphitheater with digital lighting and sound system.</p></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center"  data-aos="zoom-out" data-aos-delay="200">
                            <img src="/assets/images/Academics/coscholastic.jpeg" className='img-fluid' alt="Description image" />
                        </div>
                    </div>
        </>
    )
}

export default Academics_slide;
